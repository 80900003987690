<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input v-model="key" class="sInput minInput" placeholder="姓名、性别、电话"/>
                <a-select v-model="selStatus" style="width:100px">
                    <a-select-option :value="0">全部</a-select-option>
                    <a-select-option :value="1">空闲中</a-select-option>
                    <a-select-option :value="2">工作中</a-select-option>
                </a-select>
                <a-icon type="search" style="margin:0 20px" @click="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getData"
        infinite-scroll-disabled="dataLoadStop"
        infinite-scroll-distance="50">
        <div class="lists" v-for="item in data" :key="item.id">
            <div class="list_row">
                <div class="flex">
                    <div><label for="">姓名：</label>{{item.name}}</div>
                    <div><label for="">电话：</label>{{item.tel}}</div>

                </div>
                 <div class="flex">
                    <div><label for="">性别：</label>{{item.sex}}</div>
                    <div><label for="">技能：</label>{{item.skillid}}</div> 
                 </div>
                <div class="flex">
                    <div><label for="">是否有工伤保险：</label>{{item.insurance}}</div>
                   <div><label for="">保险到期时间：</label>{{item.deadline}}</div>
                </div>
                 <div class="flex">
                    <div><label for="">状态：</label>{{item.status}}</div>
                    <div><label for="">职务：</label>{{item.post}}</div>    
                 </div>
                <div class="flex">
                    <div><label for="">紧急联系人：</label>{{item.contactstel}}</div>
                    <div><label for="">跟进人：</label>{{item.sname}}</div>
                </div>
                 <div class="flex">
                    <div><label for="">备注：</label>{{item.remarks}}</div>
                </div>
            </div>
            <div class="btn_con">
                <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
                <a-button type="primary" size="small" ghost @click="selFollow(item.id)">分配跟进人</a-button>
                <!-- <a-button type="primary" size="small" ghost @click="showStatus(item.id)">修改工作时间</a-button> -->
                <a-button type="primary" size="small" ghost @click="toVisit(item.id)">回访记录</a-button>
                 <a-popconfirm title="确定要删除吗?"  @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>

            </div>
        </div>
        <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
          </div>
        <a-modal :afterClose="hideFollow"  v-model="visible" :centered="true" title="修改工作时间"  @ok="editStatus">
            <div class="addBox">
                <div class="add_title">请选择结束工时</div>
                 <a-date-picker v-model="endTime" :inputReadOnly="true" style="margin:30px 0"  size="large" />
            </div>
        </a-modal>
        <a-modal :afterClose="hideFollow" v-model="showFollow" :centered="true" title="分配跟进人"  @ok="setFollow">
            <div class="addBox">
                <div class="add_title" style="margin-bottom:30px">请选择跟进人</div>
                  <a-select v-model="onStaff" show-search option-filter-prop="children" class="ant-select-lg" style="width: 200px;">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                    </a-select>
            </div>
        </a-modal>
         <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
    </div>
</template>
<script>
// import $ from "jquery";
 import moment from 'moment';
import infiniteScroll from "vue-infinite-scroll";

import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},  
    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            sex:['','男','女'],  //性别
            status:['','空闲中','工作中'],  //工作状态
            insurance:['','有','无'],   // 有无工伤
            showFollow:false, //跟进人弹框
            staff:'',
            onStaff:'',
            onTemp:'',
            visible:false,  //状态弹框
            endTime:'', 
            selStatus:0,
              // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
               pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'key':this.key,'skillid':this.selSkillid,'status':this.selStatus,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/temp/tempDetail" || this.fromPath == '/scrm_wap/temp/tempAdd') {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.key
            this.selSkillid=data.skillid
            this.selStatus=data.status
        }
        this.getData();
        this.getStaff();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getData();
        },
        moment,
         // 分页
        pagesChange(current) {
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize);
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        // 获取任务列表
        getData() {
            let that=this;
            this.dataLoadStop = true;
            console.log( this.pagination.pagesize);
             requestXml("/jieb/Temporary/tempbaseshow","POST",(res) => {
                console.log(res);
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sex =  this.sex[Number(res.list[i].sex)];
                    res.list[i].status = this.status[Number(res.list[i].status)];
                    res.list[i].insurance = this.insurance[Number(res.list[i].insurance)];
                    res.list[i].deadline = getDateTime(res.list[i].deadline);
                    if( res.list[i].deadline=="1970-01-01"){res.list[i].deadline=''}
                }
                this.data = res.list;
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                // this.pagination.current += 1
                // this.dataLoadStop = false
                // }else{
                // this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{key:this.key,skillid:this.selSkillid,status:this.selStatus, "page": {'curpage': that.pagination.current,'pagesize':that.pagination.pageSize}})
        },
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getData(this.searchText);
        },
        // 修改状态
        showStatus(id){
            this.onTemp=id;
            this.visible=true;
        },
        editStatus(){
            let endTime=Math.round(moment(this.endTime).endOf('day')/1000)-1;
             requestXml("/jieb/Temporary/status","POST",(res) => {
                console.log(res)
                this.hideFollow();
                this.getData();
            },{"id": this.onTemp,'time': endTime})
        },

        // 选择跟进人
        selFollow(id){
            this.onTemp=id;
            this.showFollow=true;
        },
        hideFollow(){
            this.onTemp='';
            this.onStaff=false;
            this.visible=false;
            this.showFollow=false;
        },
        //设置跟进人 
        setFollow(){
            if(!this.onStaff){
                this.$message.info('请选择跟进人');
                return false;
            }else{
                let idArr=[this.onTemp];
                requestXml("/jieb/Temporary/followup","POST",() => {
                    this.hideFollow();
                    this.getData();
                },{id:idArr,userid:this.onStaff})
            }

        },
        // 获取成员
        getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 回访
        toVisit(id){
            this.$router.push({
                path: '/scrm_wap/temp/tempDetail',
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        edit(id){
            this.$router.push({
                path: '/scrm_wap/temp/tempAdd',
                query: {
                    id: id,
                }
            });
        },
        onDelete(id){
            requestXml("/jieb/Temporary/tempbasedel","POST",(res) => {
                console.log(res)
                this.pagination.current=1;
                this.data=[];
                this.getData();
            },{"id": id})
        }
    }
};
</script>
<style scoped>
    .minInput{
        width: 200px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }.a-inline-block{
        display: flex;
        align-items: center;
        height: 40px;

    }
    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;
    }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  

    
</style>
